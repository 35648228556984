import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TbEjecutivoRolService } from 'app/services/maestros-sync/config/tbEjecutivoRol.service';
import { TbConfiguracionAsignacionService } from 'app/services/maestros-sync/maestros';
import { TbClienteService } from 'app/services/maestros-sync/maestros/tbCliente.service';
import { TbIncotermService } from 'app/services/maestros-sync/maestros/tbIncoterm.service';
import { TbNaturalezaCargaService } from 'app/services/maestros-sync/maestros/tbNaturalezaCarga.service';
import { TbTipoBultoService } from 'app/services/maestros-sync/maestros/tbTipoBulto.service';
import { TbTipoManifiestoService } from 'app/services/maestros-sync/maestros/tbTipoManifiesto.service';
import { TbTipoMovimientoContenedorService } from 'app/services/maestros-sync/maestros/tbTipoMovimientoContenedor.service';
import { TbUbicacionComercialService } from 'app/services/maestros-sync/maestros/tbUbicacionComercial.service';
import { TbVersionIncotermService } from 'app/services/maestros-sync/maestros/tbVersionIncoterm.service';
import { BehaviorSubject, Observable, Subscription, distinctUntilChanged, forkJoin, of, take } from 'rxjs';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import {
	TbCliente,
	TbEjecutivo,
	TbEntidadContacto,
	TbIncoterm,
	TbTipoBulto,
	TbTipoEnvio,
	TbTipoManifiesto,
	TbTipoOperador,
	TbUbicacionComercial,
	TbUnidadMedida,
	TbVersionIncoterm,
	TbViaTransporte,
} from '~models/maestros';
import { FoundText } from '~shared/enums/FoundText';
import { ChangeDetectorNgSelecLabel } from '~shared/interface/ChangeDetectorNgSelecLabel';
import { INgSelectObject } from '~shared/interface/INgSelectObject';
import { ListaContenedoresComponent } from './lista-contenedores/lista-contenedores.component';
import { MessageUtilService } from '~shared/services/message-util.service';
import { FormGroupCompare } from '~shared/classes/FormGroupCompare';
import { EnumListaPersonalizada } from '~shared/enums/EnumListaPersonalizada';
import { EnumRol } from '~shared/enums/EnumRol';
import { SacCotizacionTipoOperador } from '~models/maestros-sync/maestros/SacCotizacionTipoOperador';
import { SacCotizacionDetalle } from '~models/acuerdos-comerciales/SacCotizacionDetalle';
import { TbTipoMovimientoContenedor } from '~models/maestros-sync/maestros/TbTipoMovimientoContenedor';
import { TbNaturalezaCarga } from '~models/maestros-sync/maestros/TbNaturalezaCarga';
import { SacCotizacionService } from 'app/services/acuerdos-comerciales/sacCotizacion.service';
import { MatSelectionList } from '@angular/material/list';
import { ApiResponse } from 'sumax-erp-schematics';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { AppState } from '@core/store';
import { Store } from '@ngrx/store';
import { oficinaSelected } from '@core/store/selectors/oficinas.selectors';
import { SciOficina } from '~models/config';
import { EnumEstadoCotizacion } from '~shared/enums/EnumEstadoCotizacion';
import { SacCotizacionContenedor } from '~models/acuerdos-comerciales/SacCotizacionContenedor';
import { upperCase } from 'lodash-es';
import { TbEntidadContactoService } from 'app/services/maestros/tbEntidadContacto.service';
import { InfoContactoClienteComponent } from '../../registro-cotizacion/coti-informacion-principal/info-contacto-cliente/info-contacto-cliente.component';
import { DialogData } from '~shared/interface/DialogData';
import { SciAccesosService } from 'app/services/config/sciAccesos.service';
import { currentUser } from '@core/store/selectors/auth.selectors';
import { SacCotizacionDetalleBulto } from '~models/acuerdos-comerciales/sacCotizacionDetalleBulto';
import { ListaDimensionesComponent } from './lista-dimensiones/lista-dimensiones.component';
import { InputNumberComponent, NgSelectSimpleComponent } from 'ngx-sumax-erp-component';
import { KeyEnlacesRedireccionesMaestros } from '~core/store/redirectLinks/enlaces-redireccion-maestros.store';
import { ElementsEnlace, RedirectUtilityService } from '~core/store/redirectLinks/redirect-utils.service';
import { SciUsuario } from '~shared/interface/SciUsuario';
import { AgregarContactoComponent } from '../../registro-cotizacion/coti-informacion-principal/agregar-contacto/agregar-contacto.component';

export const codIncoterms = ['EXW', 'DDP', 'DDU', 'DAP'];

@Component({
	selector: 'app-solicitud-cotizacion-dialog',
	templateUrl: './solicitud-cotizacion-dialog.component.html',
	styleUrls: ['./solicitud-cotizacion-dialog.component.scss'],
})
export class SolicitudCotizacionDialogComponent implements OnInit, OnDestroy {
	@HostListener('document:keydown', ['$event']) onKeyDown(e: KeyboardEvent): void {
		if (e) {
			if (e.key === 'Escape') this.closeDialog();
		}
	}
	@ViewChild('tbTipoOperadores') tbTipoOperadoresElement!: MatSelectionList;
	@ViewChild('cantidadBultos') cantidadBultosElement!: InputNumberComponent;
	@ViewChild('sacCotizacionContenedores', { static: false }) sacCotizacionContenedores!: NgSelectSimpleComponent;
	@ViewChild('sacCotizacionDimensiones', { static: false }) sacCotizacionDimensiones!: NgSelectSimpleComponent;
	tbTipoManifiesto$: Observable<INgSelectObject<TbTipoManifiesto>[]> = of([]) as Observable<INgSelectObject<TbTipoManifiesto>[]>;
	tbViaTransporte$: Observable<NgSelectOption<TbViaTransporte>[]> = of([]) as Observable<NgSelectOption<TbViaTransporte>[]>;
	tbTipoOperador$: Observable<NgSelectOption<TbTipoOperador>[]> = of([]) as Observable<NgSelectOption<TbTipoOperador>[]>;
	tbTipoMovimientoContenedor$: Observable<INgSelectObject<TbTipoMovimientoContenedor>[]> = of([]) as Observable<INgSelectObject<TbTipoMovimientoContenedor>[]>;
	tbVersionIncoterm$: Observable<INgSelectObject<TbVersionIncoterm>[]> = of([]) as Observable<INgSelectObject<TbVersionIncoterm>[]>;
	tbNaturalezaCarga$: Observable<INgSelectObject<TbNaturalezaCarga>[]> = of([]) as Observable<INgSelectObject<TbNaturalezaCarga>[]>;
	tbIncoterm$: Observable<INgSelectObject<TbIncoterm>[]> = of([]) as Observable<INgSelectObject<TbIncoterm>[]>;
	tbCliente$: Observable<INgSelectObject<TbCliente>[]> = of([]) as Observable<INgSelectObject<TbCliente>[]>;
	tbContactoCliente$ = of<NgSelectOption<TbEntidadContacto>[]>([]);
	tbEjecutivoRol$: Observable<NgSelectOption<TbEjecutivo>[]> = of([]) as Observable<NgSelectOption<TbEjecutivo>[]>;
	tbTipoEnvio$: Observable<INgSelectObject<TbTipoEnvio>[]> = of([]) as Observable<INgSelectObject<TbTipoEnvio>[]>;
	tbTipoBulto$: Observable<INgSelectObject<TbTipoBulto>[]> = of([]) as Observable<INgSelectObject<TbTipoBulto>[]>;
	tbUnidadMedidaMasa$: Observable<NgSelectOption<TbUnidadMedida>[]> = of([]) as Observable<NgSelectOption<TbUnidadMedida>[]>;
	tbUnidadMedidaVolumen$: Observable<NgSelectOption<TbUnidadMedida>[]> = of([]) as Observable<NgSelectOption<TbUnidadMedida>[]>;
	tbUbicacionComercialEmbarque$: Observable<INgSelectObject<TbUbicacionComercial>[]> = of([]) as Observable<INgSelectObject<TbUbicacionComercial>[]>;
	tbUbicacionComercialLlegada$: Observable<INgSelectObject<TbUbicacionComercial>[]> = of([]) as Observable<INgSelectObject<TbUbicacionComercial>[]>;
	sacCotizacionContenedorItems$: Observable<INgSelectObject<SacCotizacionContenedor>[]> = of([]) as Observable<INgSelectObject<SacCotizacionContenedor>[]>;
	sacCotizacionDetalleBulto$: Observable<INgSelectObject<SacCotizacionDetalleBulto>[]> = of([]) as Observable<INgSelectObject<SacCotizacionDetalleBulto>[]>;
	listTbTipoMovimientoContenedor: TbTipoMovimientoContenedor[] = [];
	listViaTransporte: TbViaTransporte[] = [];
	private _formGroup!: UntypedFormGroup;
	private listSacCotizacionContenedores: SacCotizacionContenedor[] = [];
	private listSacCotizacionDimensiones: SacCotizacionDetalleBulto[] = [];
	private _isOpenDialog = false;
	foundTextTbUbicacionComercialEmbarque = 'Seleccione el tipo de operación';
	foundTextTbUbicacionComercialLlegada = 'Seleccione el tipo de operación';
	foundTextTbVersionIncoterm = 'Seleccione la versión de incoterm';
	notFoundTextTbContactoCliente = 'Seleccione el cliente';
	o1: Record<string, unknown> = {};
	sciOficina?: SciOficina;
	notFoundText = 'Seleccione vía de transporte';
	tipoOperadorTextValid = 'El campo Tipo de operador es requerido';
	labelVolumen = 'Peso/Volumen';
	labelPuertoOrigen = 'Aeropuerto de origen';
	labelPuertoDestino = 'Aeropuerto de destino';
	isBand = false;
	isFirstTimeNet = false;
	user!: string;
	idUsuario = 0;
	sciUsuario!: SciUsuario;
	sciUsuarioEjecutivo!: TbEjecutivo | null;
	tbTipoOperadoresStyle = '';
	isLoadingBtnSave = false;
	isCargaSuelta = false;
	tbTipoOperadoresDefault: TbTipoOperador[] = [];
	listTbTipoOperadoresSelected: TbTipoOperador[] = [];
	isDataDefault: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isAereo = true;

	subscription$ = new Subscription();

	constructor(
		private readonly store: Store<AppState>,
		private readonly dialogRef: MatDialogRef<SolicitudCotizacionDialogComponent>,
		private readonly dialog: MatDialog,
		private readonly fb: UntypedFormBuilder,
		private readonly tbTipoManifiestoService: TbTipoManifiestoService,
		private readonly tbVersionIncotermService: TbVersionIncotermService,
		private readonly tbIncotermService: TbIncotermService,
		private readonly tbTipoMovimientoContenedorService: TbTipoMovimientoContenedorService,
		private readonly tbClienteService: TbClienteService,
		private readonly tbEjecutivoRolService: TbEjecutivoRolService,
		private readonly tbConfiguracionAsignacionService: TbConfiguracionAsignacionService,
		private readonly tbNaturalezaCargaService: TbNaturalezaCargaService,
		private readonly tbTipoBultoService: TbTipoBultoService,
		private readonly tbUbicacionComercialService: TbUbicacionComercialService,
		private readonly _tbEntidadContactoService: TbEntidadContactoService,
		private readonly messageUtilService: MessageUtilService,
		private readonly sacCotizacionService: SacCotizacionService,
		private readonly sciAccesosService: SciAccesosService,
		public readonly _redirectUtilityService: RedirectUtilityService
	) {
		this._selectStore();
		this._llenarDatos();
	}

	ngOnDestroy(): void {
		this.subscription$.unsubscribe();
	}

	ngOnInit(): void {
		this._createForm();
		this._dataDefault();
		this._valueChanges();
		this._findEjecutivo();
	}

	private _createForm(): void {
		this._formGroup = this.fb.group({
			tbTipoManifiesto: [null, [Validators.required]],
			tbViaTransporte: [null, [Validators.required]],
			tbTipoMovimientoContenedor: [null, [Validators.required]],
			tbTipoOperadores: [[], [Validators.required]],
			tbVersionIncoterm: [null],
			tbIncoterm: [null],
			tbNaturalezaCarga: [null],
			tbTipoEnvio: [null],
			tbCliente: [null, [Validators.required]],
			tbEntidadContacto: [null],
			tbEjecutivoRol: [null],
			tbUbicacionComercialEmbarque: [null, [Validators.required]],
			tbUbicacionComercialLlegada: [null, [Validators.required]],
			direccionRecojo: [''],
			direccionEntrega: [''],
			sacCotizacionContenedores: [null],
			sacCotizacionDimensiones: [null],
			pesoBruto: [],
			tbUnidadMedidaPeso: [null],
			cantidadBultos: [],
			tbTipoBulto: [null],
			volumen: [],
			tbUnidadMedidaVolumen: [null],
		});
		this.formGroup.get('direccionRecojo')?.disable();
		this.formGroup.get('direccionEntrega')?.disable();
		this.o1 = { ...(this.formGroup.value as Record<string, unknown>) };
	}

	private _selectStore(): void {
		this.subscription$.add(
			this.store
				.select(oficinaSelected)
				.pipe(take(1))
				.subscribe((e) => {
					if (e) this.sciOficina = e;
				})
		);
		this.subscription$.add(
			this.store
				.select(currentUser)
				.pipe(take(1))
				.subscribe((e) => {
					if (e) {
						this.user = e?.username;
						this.idUsuario = e.id;
						this.sciUsuario = e as unknown as SciUsuario;
					}
				})
		);
	}

	onChangeTipoFormato(tbTipoOperadores: TbTipoOperador[]): void {
		this.listTbTipoOperadoresSelected = tbTipoOperadores;
		if (tbTipoOperadores.length > 0) {
			this.tbTipoOperadoresStyle = '';
			this.isBand = false;
		}
	}

	private _dataDefault(): void {
		this.isDataDefault.subscribe((e) => {
			if (e) {
				const idsDefault = this.tbTipoOperadoresDefault.map((res) => res.idTipoOperador);
				this.tbTipoOperador$.subscribe((c) => {
					if (Array.isArray(c)) {
						const filtrado = c.filter((f) => idsDefault.includes(f.idTipoOperador ?? 0));
						this.formGroup.get('tbTipoOperadores')?.setValue(filtrado);
						this.o1 = { ...(this.formGroup.value as Record<string, unknown>) };
					}
				});
				this.tbViaTransporte$.subscribe((data) => {
					this.formGroup.get('tbViaTransporte')?.setValue(data[0]);
					this.o1 = { ...(this.formGroup.value as Record<string, unknown>) };
				});
			}
		});
	}

	private _valueChanges(): void {
		this.formGroup
			.get('tbTipoManifiesto')
			?.valueChanges.pipe(distinctUntilChanged())
			.subscribe((res: TbTipoManifiesto) => {
				if (res) {
					this.foundTextTbUbicacionComercialEmbarque = FoundText.LabelFoundTextInit;
					this.foundTextTbUbicacionComercialLlegada = FoundText.LabelFoundTextInit;
					if (res.codigo == '01') {
						this.tbUbicacionComercialEmbarque$ = of([]);
						this.foundTextTbUbicacionComercialLlegada = FoundText.LabelFoundTextResponseIsEmpty;
						this.foundTextTbUbicacionComercialEmbarque = FoundText.LabelFoundTextInit;
						this._onChangePuertoLlegadaIngreso();
					} else if (res.codigo == '02') {
						this.tbUbicacionComercialLlegada$ = of([]);
						this.foundTextTbUbicacionComercialLlegada = FoundText.LabelFoundTextResponseIsEmpty;
						this.foundTextTbUbicacionComercialLlegada = FoundText.LabelFoundTextInit;
						this._onChangePuertEmbarqueSalida();
					}
				} else {
					this.foundTextTbUbicacionComercialEmbarque = 'Seleccione el tipo de operación';
					this.foundTextTbUbicacionComercialLlegada = 'Seleccione el tipo de operación';
				}
			});
		this.formGroup.get('tbVersionIncoterm')?.valueChanges.subscribe((res: TbVersionIncoterm) => {
			if (!res) {
				this.foundTextTbVersionIncoterm = 'Seleccione la versión de incoterm';
				this.tbIncoterm$ = of([]);
				this.formGroup.get('tbIncoterm')?.setValue(null, { eventEmit: false });
			}
			this.tbIncoterm$ = this.tbIncotermService.getNgSelectRegistroDoc(res?.codigo, true);
		});
		this.formGroup.get('tbIncoterm')?.valueChanges.subscribe((res: TbIncoterm) => {
			if (res && codIncoterms.includes(res.codigo as string)) {
				this.formGroup.get('direccionRecojo')?.enable();
				this.formGroup.get('direccionEntrega')?.enable();
			} else {
				this.formGroup.get('direccionRecojo')?.disable();
				this.formGroup.get('direccionEntrega')?.disable();
			}
		});
		this.formGroup
			.get('tbViaTransporte')
			?.valueChanges.pipe(distinctUntilChanged())
			.subscribe((res: TbViaTransporte) => {
				if (res) {
					if (res.codigo === 'A') {
						this.labelVolumen = 'Peso/Volumen';
						this.labelPuertoOrigen = 'Aeropuerto de origen';
						this.labelPuertoDestino = 'Aeropuerto de destino';
						this.isAereo = true;
						this.isCargaSuelta = false;
						this.listSacCotizacionContenedores = [];
						this.listSacCotizacionDimensiones = [];
						this.sacCotizacionContenedorItems$ = of([]);
						if (!this.isFirstTimeNet) {
							this.tbUnidadMedidaVolumen$ = this.tbConfiguracionAsignacionService.findAllUnidadMedidaByCodigoSistema(EnumListaPersonalizada.SACCOAUNIDADVOLU);
						}
						this.formGroup.get('sacCotizacionContenedores')?.reset();
						this.formGroup.get('sacCotizacionDimensiones')?.reset();
						this.sacCotizacionDetalleBulto$ = of([]);
						this.formGroup.get('tbUnidadMedidaPeso')?.reset();
						this.formGroup.get('cantidadBultos')?.reset();
						this.formGroup.get('tbTipoBulto')?.reset();
						this.formGroup.get('pesoBruto')?.reset();
						this.formGroup.get('volumen')?.reset();
						this.formGroup.get('tbUnidadMedidaVolumen')?.reset();
						this.formGroup.get('tbTipoMovimientoContenedor')?.clearValidators();
						this.formGroup.get('tbTipoMovimientoContenedor')?.reset();
					} else if (res.codigo === 'M') {
						const band = this.formGroup.get('tbTipoMovimientoContenedor')?.value as TbTipoMovimientoContenedor;
						if (band) {
							if (band.codigo == '2') this.isCargaSuelta = true;
							else this.isCargaSuelta = false;
						}
						this.labelVolumen = 'Volumen';
						this.labelPuertoOrigen = 'Puerto de origen';
						this.labelPuertoDestino = 'Puerto de destino';
						this.isAereo = false;
						this.listSacCotizacionDimensiones = [];
						this.sacCotizacionDetalleBulto$ = of([]);
						this.tbUnidadMedidaVolumen$ = this.tbConfiguracionAsignacionService.findAllUnidadMedidaByCodigoSistema(EnumListaPersonalizada.SACCOMUNIDADVOLU);
						this.formGroup.get('sacCotizacionDimensiones')?.reset();
						this.formGroup.get('tbUnidadMedidaPeso')?.reset();
						this.formGroup.get('tbUnidadMedidaVolumen')?.reset();
						this.formGroup.get('tbTipoMovimientoContenedor')?.addValidators(Validators.required);
						this.formGroup.get('cantidadBultos')?.reset();
						this.formGroup.get('pesoBruto')?.reset();
						this.formGroup.get('volumen')?.reset();
						this.formGroup.get('tbTipoBulto')?.reset();
						this.formGroup.get('tbTipoMovimientoContenedor')?.reset();
					} else {
						this.labelVolumen = 'Volumen';
						this.labelPuertoOrigen = 'Puerto de origen';
						this.labelPuertoDestino = 'Puerto de destino';
						this.isAereo = false;
						this.isCargaSuelta = false;
						this.listSacCotizacionDimensiones = [];
						this.sacCotizacionDetalleBulto$ = of([]);
						this.tbUnidadMedidaVolumen$ = this.tbConfiguracionAsignacionService.findAllUnidadMedidaByCodigoSistema(EnumListaPersonalizada.SACCOMUNIDADVOLU);
						this.formGroup.get('sacCotizacionDimensiones')?.reset();
						this.formGroup.get('tbUnidadMedidaPeso')?.reset();
						this.formGroup.get('tbUnidadMedidaVolumen')?.reset();
						this.formGroup.get('tbTipoMovimientoContenedor')?.addValidators(Validators.required);
						this.formGroup.get('cantidadBultos')?.reset();
						this.formGroup.get('volumen')?.reset();
						this.formGroup.get('tbTipoBulto')?.reset();
						this.formGroup.get('tbTipoMovimientoContenedor')?.reset();
					}
					this.isFirstTimeNet = false;
					this.notFoundText = 'No se encontraron resultados';
					this._filtradoTipoFlete();
				}
			});
		this.formGroup
			.get('tbTipoMovimientoContenedor')
			?.valueChanges.pipe(distinctUntilChanged())
			.subscribe((res: TbTipoMovimientoContenedor) => {
				if (res) {
					const tbViaTransporte = this.formGroup.get('tbViaTransporte')?.value as TbViaTransporte;
					if (tbViaTransporte.codigo == 'M') {
						if (res.codigo == '2') {
							this.isCargaSuelta = true;
							this.listSacCotizacionContenedores = [];
							this.sacCotizacionContenedorItems$ = of([]);
							this.formGroup.get('sacCotizacionContenedores')?.reset();
						} else {
							this.isCargaSuelta = false;
							this.listSacCotizacionDimensiones = [];
							this.sacCotizacionDetalleBulto$ = of([]);
							this.formGroup.get('sacCotizacionDimensiones')?.reset();
							this.formGroup.get('cantidadBultos')?.reset();
							this.formGroup.get('volumen')?.reset();
							this.formGroup.get('tbTipoBulto')?.reset();
							this.formGroup.get('tbUnidadMedidaVolumen')?.reset();
						}
					} else this.isCargaSuelta = false;
				}
			});
		this.formGroup
			.get('tbCliente')
			?.valueChanges.pipe(distinctUntilChanged())
			.subscribe((res: TbCliente) => {
				if (res) {
					this.notFoundTextTbContactoCliente = 'No se encontraron resultados';
					const getCliente = (): TbCliente => res;
					this.tbContactoCliente$ = this._tbEntidadContactoService.getSelectList(getCliente().idCliente, false);
				} else this.notFoundTextTbContactoCliente = 'Seleccione el cliente';
			});
	}

	setValueNgSelectPuertoEmbarque(change: ChangeDetectorNgSelecLabel): void {
		const tbTipoManifiesto = this.formGroup.get('tbTipoManifiesto')?.value as TbTipoManifiesto;
		if (tbTipoManifiesto !== null) {
			if (tbTipoManifiesto.codigo == '01') {
				if (change.term && change.term.length > 2) {
					this.tbUbicacionComercialEmbarque$ = this.tbUbicacionComercialService.getSelectListByTipoBusqueda('I', upperCase(change.term));
					this.tbUbicacionComercialEmbarque$.subscribe((e) => {
						if (e.length === 0) this.foundTextTbUbicacionComercialEmbarque = FoundText.LabelFoundTextResponseIsEmpty;
					});
				} else {
					this.tbUbicacionComercialEmbarque$ = of([]);
					this.foundTextTbUbicacionComercialEmbarque = FoundText.LabelFoundTextInit;
				}
			} else if (tbTipoManifiesto.codigo == '02') {
				this._onChangePuertEmbarqueSalida(upperCase(change.term));
			}
		}
	}

	setValueNgSelectPuertoLlegada(change: ChangeDetectorNgSelecLabel): void {
		const tbTipoManifiesto = this.formGroup.get('tbTipoManifiesto')?.value as TbTipoManifiesto;
		if (tbTipoManifiesto !== null) {
			if (tbTipoManifiesto.codigo == '01') {
				this._onChangePuertoLlegadaIngreso(upperCase(change.term));
			} else if (tbTipoManifiesto.codigo == '02') {
				if (change.term && change.term.length > 2) {
					this.tbUbicacionComercialLlegada$ = this.tbUbicacionComercialService.getSelectListByTipoBusqueda('I', upperCase(change.term));
					this.tbUbicacionComercialLlegada$.subscribe((e) => {
						if (e) this.foundTextTbUbicacionComercialLlegada = FoundText.LabelFoundTextResponseIsEmpty;
					});
				} else {
					this.tbUbicacionComercialLlegada$ = of([]);
					this.foundTextTbUbicacionComercialLlegada = FoundText.LabelFoundTextInit;
				}
			}
		}
	}

	private _onChangePuertEmbarqueSalida(nombre = ''): void {
		this.tbUbicacionComercialEmbarque$ = this.tbUbicacionComercialService.getSelectListByTipoBusqueda('N', nombre);
	}

	private _onChangePuertoLlegadaIngreso(nombre = ''): void {
		this.tbUbicacionComercialLlegada$ = this.tbUbicacionComercialService.getSelectListByTipoBusqueda('N', nombre);
	}

	listadoContenedores(): void {
		this.dialog
			.open(ListaContenedoresComponent, {
				width: '600px',
				data: {
					data: this.listSacCotizacionContenedores,
					solicitud: true,
				},
				disableClose: true,
			})
			.afterClosed()
			.subscribe((res: SacCotizacionContenedor[]) => {
				if (res) {
					if (res.length == 0) {
						this.formGroup.get('sacCotizacionContenedores')?.setValue(null);
					} else this.formGroup.get('sacCotizacionContenedores')?.setValue([]);
					this.listSacCotizacionContenedores = [];
					this.listSacCotizacionContenedores.push(...res);
					this.cantidadBultosElement.focus();
					this.sacCotizacionContenedorItems$ = this._convertAllToNgSelectContenedores(this.listSacCotizacionContenedores);
					this.formGroup.get('sacCotizacionContenedores')?.setValue(this._convertToNgSelectContenedores(this.listSacCotizacionContenedores[0]));
				}
			});
	}

	private _convertToNgSelectContenedores(e: SacCotizacionContenedor): INgSelectObject<SacCotizacionContenedor> {
		return {
			...e,
			value: e.idCotizacionContenedor,
			label: `${e.cantidad} X ${e.tbTipoContenedor?.descripcion}`,
		};
	}

	private _convertAllToNgSelectContenedores(list: SacCotizacionContenedor[]): Observable<INgSelectObject<SacCotizacionContenedor>[]> {
		return of(list.map((e) => this._convertToNgSelectContenedores(e)));
	}

	listadoDimensiones(): void {
		this.dialog
			.open(ListaDimensionesComponent, {
				width: '1200px',
				data: {
					list: this.listSacCotizacionDimensiones,
					isCargaSuelta: this.isCargaSuelta,
				},
				disableClose: true,
			})
			.afterClosed()
			.subscribe((res: SacCotizacionDetalleBulto[]) => {
				if (res) {
					if (res.length == 0) {
						this.formGroup.get('sacCotizacionDimensiones')?.setValue(null);
					} else this.formGroup.get('sacCotizacionDimensiones')?.setValue([]);
					this.listSacCotizacionDimensiones = [];
					this.listSacCotizacionDimensiones.push(...res);
					this.cantidadBultosElement.focus();
					this.sacCotizacionDetalleBulto$ = this._convertAllToNgSelectDimensiones(this.listSacCotizacionDimensiones);
					this._operacionesDimensiones(this.listSacCotizacionDimensiones);
					this.formGroup.get('sacCotizacionDimensiones')?.setValue(this._convertToNgSelectDimensiones(this.listSacCotizacionDimensiones[0]));
				}
			});
	}

	private _convertToNgSelectDimensiones(e: SacCotizacionDetalleBulto): INgSelectObject<SacCotizacionDetalleBulto> {
		return {
			...e,
			value: e.idCotizacionDetalleBulto,
			label: `(${e.cantidadBulto})  ${e.largoBulto} X ${e.anchoBulto} X ${e.altoBulto} ${e.tbUnidadMedida?.nombre}`,
		};
	}

	private _convertAllToNgSelectDimensiones(list: SacCotizacionDetalleBulto[]): Observable<INgSelectObject<SacCotizacionDetalleBulto>[]> {
		return of(list.map((e) => this._convertToNgSelectDimensiones(e)));
	}

	private _operacionesDimensiones(list: SacCotizacionDetalleBulto[]): void {
		let sumaBultos = 0;
		let sumaPesoBultos = 0;
		let sumaPesoVolumen = 0;

		sumaPesoBultos = list.reduce((acumulador, elemento) => acumulador + (elemento.pesoTotal ?? 0), 0);

		if (this.isCargaSuelta) {
			sumaBultos = list.reduce((acumulador, elemento) => acumulador + (elemento.cantidadBulto ?? 0), 0);
			sumaPesoVolumen = list.reduce((acumulador, elemento) => acumulador + (elemento.volumen ?? 0), 0);
		} else {
			sumaBultos = list.reduce((acumulador, elemento) => acumulador + (elemento.cantidadBulto ?? 0), 0);
			sumaPesoVolumen = list.reduce((acumulador, elemento) => acumulador + (elemento.pesoVolumetrico ?? 0), 0);
		}

		this.formGroup.get('cantidadBultos')?.setValue(Number(sumaBultos));
		this.formGroup.get('pesoBruto')?.setValue(Number(sumaPesoBultos));
		this.formGroup.get('volumen')?.setValue(Number(sumaPesoVolumen));

		if (this.isCargaSuelta) {
			this.tbUnidadMedidaVolumen$.subscribe((e) => {
				this.formGroup.get('tbUnidadMedidaVolumen')?.setValue(e[0]);
			});
			this._setNgSelectSimple<TbUnidadMedida>('tbUnidadMedidaVolumen', 'M', this.tbUnidadMedidaVolumen$);
		} else {
			this._setNgSelectSimple<TbUnidadMedida>('tbUnidadMedidaVolumen', 'A', this.tbUnidadMedidaVolumen$);
			this.tbTipoBulto$.subscribe((e) => {
				const tipoBulto = e.find((c) => c.codigo === 'BUL');
				if (tipoBulto) {
					this.formGroup.get('tbTipoBulto')?.setValue(tipoBulto);
				}
			});
		}
		this.tbUnidadMedidaMasa$.subscribe((e) => {
			const unidadMedidaKgm = e.find((c) => c.codigo === 'KGM');
			if (unidadMedidaKgm) {
				this.formGroup.get('tbUnidadMedidaPeso')?.setValue(unidadMedidaKgm);
			}
		});
	}

	private _setNgSelectSimple<T>(formControl: string, codigo: string, items: Observable<INgSelectObject<T>[]>): void {
		const codigoMap: Record<string, string> = {
			A: 'KGV',
			M: 'MTQ', //carga suelta
		};

		const unidadMedidaCodigo = codigoMap[codigo];

		if (unidadMedidaCodigo) {
			items.subscribe((e) => {
				const unidadMedida = e.find((c: INgSelectObject<T>) => c.codigo === unidadMedidaCodigo);
				if (unidadMedida) {
					this.formGroup.get(formControl)?.setValue(unidadMedida);
				}
			});
		}
	}

	onSubmit(): void {
		if (this.formGroup.valid) {
			this.isLoadingBtnSave = true;
			const cotizacion = this._convertFormToSacCotizacion();
			this.subscription$.add(
				this.sacCotizacionService.insertSolicitudCotizacion(cotizacion).subscribe((res: ApiResponse) => {
					this.messageUtilService.getAlertSuccessBasic(res.mensaje);
					this.isLoadingBtnSave = false;
					this.dialogRef.close({ refreshList: true, cotizacion: res.data });
				})
			);
		} else {
			const tbTipoOperadores = this.formGroup.get('tbTipoOperadores')?.value;
			if (tbTipoOperadores == null || tbTipoOperadores.length == 0) {
				this.tbTipoOperadoresStyle = 'borde-rojo';
				this.isBand = true;
			} else this.tbTipoOperadoresStyle = '';
			this.formGroup.markAllAsTouched();
		}
	}

	private _convertFormToSacCotizacion(): SacCotizacion {
		const tipoOperadores = this.listTbTipoOperadoresSelected;
		const listOperadores: SacCotizacionTipoOperador[] = tipoOperadores.map((e) => {
			const sacCotizacionTipoOperador: SacCotizacionTipoOperador = {
				tbTipoOperador: e,
				idTipoOperador: e.idTipoOperador,
			};
			return sacCotizacionTipoOperador;
		});

		const sacCotizacionDetalle: SacCotizacionDetalle = {};
		const sacCotizacionDetalles: SacCotizacionDetalle[] = [];

		if (
			this.formGroup.get('volumen')?.value ||
			this.formGroup.get('cantidadBultos')?.value ||
			this.formGroup.get('tbTipoBulto')?.value ||
			this.formGroup.get('pesoBruto')?.value ||
			this.formGroup.get('tbUnidadMedidaPeso')?.value ||
			this.formGroup.get('tbUnidadMedidaVolumen')?.value ||
			this.formGroup.get('tbNaturalezaCarga')?.value
		) {
			sacCotizacionDetalle.volumen = this.formGroup.get('volumen')?.value as number;
			sacCotizacionDetalle.cantidadBultos = this.formGroup.get('cantidadBultos')?.value as number;
			sacCotizacionDetalle.tbTipoBulto = this.formGroup.get('tbTipoBulto')?.value as TbTipoBulto;
			sacCotizacionDetalle.idTipoBulto = sacCotizacionDetalle.tbTipoBulto?.idTipoBulto ?? 0;
			sacCotizacionDetalle.pesoBruto = this.formGroup.get('pesoBruto')?.value as number;
			sacCotizacionDetalle.tbUnidadMedidaPeso = this.formGroup.get('tbUnidadMedidaPeso')?.value as TbUnidadMedida;
			sacCotizacionDetalle.idUnidadMedidaPeso = sacCotizacionDetalle.tbUnidadMedidaPeso?.idUnidadMedida;
			sacCotizacionDetalle.tbUnidadMedidaVolumen = this.formGroup.get('tbUnidadMedidaVolumen')?.value as TbUnidadMedida;
			sacCotizacionDetalle.idUnidadMedidaVolumen = sacCotizacionDetalle.tbUnidadMedidaVolumen?.idUnidadMedida;
			sacCotizacionDetalle.tbNaturalezaCarga = this.formGroup.get('tbNaturalezaCarga')?.value as TbNaturalezaCarga;
			sacCotizacionDetalle.idNaturalezaCarga = sacCotizacionDetalle.tbNaturalezaCarga?.idNaturalezaCarga ?? 0;
			sacCotizacionDetalle.sacCotizacionDetalleBultos = this.listSacCotizacionDimensiones;

			sacCotizacionDetalles.push(sacCotizacionDetalle);
		}

		const cotizacion: SacCotizacion = {
			idTipoManifiesto: (this.formGroup.get('tbTipoManifiesto')?.value as TbTipoManifiesto).idTipoManifiesto ?? 0,
			idViaTransporte: (this.formGroup.get('tbViaTransporte')?.value as TbViaTransporte).idViaTransporte ?? 0,
			idTipoMovimientoContenedor: (this.formGroup.get('tbTipoMovimientoContenedor')?.value as TbTipoMovimientoContenedor)?.idTipoMovimientoContenedor ?? 0,
			idIncoterm: (this.formGroup.get('tbIncoterm')?.value as TbIncoterm)?.idIncoterm ?? 0,
			idCliente: (this.formGroup.get('tbCliente')?.value as TbCliente)?.idCliente,
			idEntidadContactoCliente: (this.formGroup.get('tbEntidadContacto')?.value as TbEntidadContacto)?.idEntidadContacto,
			razonSocialCliente: (this.formGroup.get('tbCliente')?.value as TbCliente)?.tbEntidad.razonSocial,
			idEjecutivoVendedor: this.sciUsuarioEjecutivo?.idEjecutivo,
			idUbicacionComercialEmbarque: (this.formGroup.get('tbUbicacionComercialEmbarque')?.value as TbUbicacionComercial)?.idUbicacionComercial,
			idUbicacionComercialLlegada: (this.formGroup.get('tbUbicacionComercialLlegada')?.value as TbUbicacionComercial)?.idUbicacionComercial,
			sacCotizacionContenedores: this.listSacCotizacionContenedores,
			direccionRecojo: this.formGroup.get('direccionRecojo')?.value as string,
			direccionEntrega: this.formGroup.get('direccionEntrega')?.value as string,
			idTipoEnvio: (this.formGroup.get('tbTipoEnvio')?.value as TbTipoEnvio)?.idTipoEnvio,
			idOficina: this.sciOficina?.idOficina,
			idTipoEstadoCotizacion: Number(EnumEstadoCotizacion.SOLICITADO),
			sacCotizacionTipoOperadores: listOperadores,
			sacCotizacionDetalles: sacCotizacionDetalles,
			sacCotizacionEstados: [{ idTipoEstadoCotizacion: Number(EnumEstadoCotizacion.SOLICITADO), idUsuario: this.idUsuario }],
		};

		return cotizacion;
	}

	private _findEjecutivo(): void {
		this.tbEjecutivoRolService.findAllByCodigoRolAndEstadoNgSelect('EC', true).subscribe((e) => {
			const userEjecutivoFind = e.find((c) => c.tbPersona?.idPersona === this.sciUsuario.tbPersona?.idPersona);
			this.sciUsuarioEjecutivo = { ...userEjecutivoFind };
		});
	}

	refreshCliente(): void {
		this.tbCliente$ = this.tbClienteService.findAllNgSelectByEstado(true);
	}

	refreshContactoCliente(): void {
		const tbCliente = this.formGroup.get('tbCliente')?.value;
		this.tbContactoCliente$ = this._tbEntidadContactoService.getSelectList(tbCliente.idCliente, false);
	}

	descripcionCtcCliente(): void {
		const data = new DialogData<TbEntidadContacto>();
		const idCliente = this.formGroup.get('tbCliente')?.value as TbCliente;
		const idCtcCliente = this.formGroup.get('tbEntidadContacto')?.value as TbEntidadContacto;
		if (!idCliente || !idCtcCliente) {
			return;
		}
		data.data = idCtcCliente;
		this.subscription$.add(
			this.dialog
				.open(InfoContactoClienteComponent, {
					width: '450px',
					data,
				})
				.afterClosed()
				.subscribe()
		);
	}

	onAddContactoCliente(): void {
		const idCliente = this._formGroup.get('tbCliente')?.value as TbCliente;
		const data = new DialogData<TbCliente>();
		data.data = idCliente;
		this._isOpenDialog = true;
		this.subscription$.add(
			this.dialog
				.open(AgregarContactoComponent, {
					width: '350px',
					data,
				})
				.afterClosed()
				.subscribe((idEntidadContacto) => {
					if (idEntidadContacto) {
						this.actualizarContactos(idEntidadContacto);
					}
				})
		);
	}

	actualizarContactos(idEntidadContacto: TbEntidadContacto): void {
		const tbCliente = this._formGroup.get('tbCliente')?.value as TbCliente;
		if (!tbCliente?.idCliente) return;

		this._tbEntidadContactoService.getSelectList(tbCliente.idCliente, false).subscribe((contactos) => {
			this.tbContactoCliente$ = of(contactos);

			const contactoEditado = contactos.find((item) => item.value === idEntidadContacto);
			if (contactoEditado) {
				this.formGroup.get('tbEntidadContacto')?.setValue(contactoEditado);
			}

			this.refreshContactoCliente();
		});
	}

	isClienteSelected(): boolean {
		return !!this._formGroup.get('tbCliente')?.value;
	}

	keyNext(): void {
		if (this.isAereo || this.isCargaSuelta) this.sacCotizacionDimensiones.focus();
		else this.sacCotizacionContenedores.focus();
	}

	closeDialog(): void {
		if (this.isFormDifferent()) this._messageCloseDialog();
		else this.dialogRef.close({ refreshList: false });
	}

	isFormDifferent(): boolean {
		return FormGroupCompare.isDifferentForm(this.o1, this.formGroup.value);
	}

	private _messageCloseDialog(): void {
		this.messageUtilService.getMessageQuestion('¿Desea cancelar la solicitud de cotización?', 'Los cambios realizados no se guardarán').then((res) => {
			if (res.value) this.dialogRef.close({ refreshList: false });
		});
	}

	private _llenarDatos(): void {
		forkJoin([
			this.tbTipoManifiestoService.findAllNgSelectByEstado(true),
			this.tbConfiguracionAsignacionService.findAllViaTransporteByCodigoSistema(EnumListaPersonalizada.SACCOVIATRANS),
			this.tbTipoMovimientoContenedorService.findAllNgSelectByEstado(true),
			this.tbVersionIncotermService.findAllNgSelectByEstado(true),
			this.tbNaturalezaCargaService.findAllNgSelectByEstado(true),
			this.tbClienteService.findAllNgSelectByEstado(true),
			this.tbEjecutivoRolService.getSelectList(EnumRol.EC, false),
			this.tbConfiguracionAsignacionService.findAllTbEnvioaByCodigoSistema(EnumListaPersonalizada.SACCOTIPOENVIO),
			this.tbTipoBultoService.findAllNgSelectByEstado(true),
			this.tbConfiguracionAsignacionService.findAllUnidadMedidaByCodigoSistema(EnumListaPersonalizada.SACCOUNIDADMASA),
			this.tbConfiguracionAsignacionService.findAllUnidadMedidaByCodigoSistema(EnumListaPersonalizada.SACCOAUNIDADVOLU),
			this.tbConfiguracionAsignacionService.findAllTipoOperadorByCodigoSistema(EnumListaPersonalizada.SACCOTIPOOPER),
			this.sciAccesosService.findAllSciSistemaByUsuario(this.user),
		]).subscribe(([c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13]) => {
			this.tbTipoManifiesto$ = of(c1);
			this.tbViaTransporte$ = of(c2);
			this.listViaTransporte = c2;
			this.tbTipoMovimientoContenedor$ = of(c3);
			this.listTbTipoMovimientoContenedor = c3;
			this.tbVersionIncoterm$ = of(c4);
			this.tbNaturalezaCarga$ = of(c5);
			this.tbCliente$ = of(c6);
			this.tbEjecutivoRol$ = of(c7);
			this.tbTipoEnvio$ = of(c8);
			this.tbTipoBulto$ = of(c9);
			this.tbUnidadMedidaMasa$ = of(c10);
			this.isFirstTimeNet = true;
			this.tbUnidadMedidaVolumen$ = of(c11);
			this._filtradoTipoFlete();
			this.tbTipoOperador$ = of(c12);
			this.tbTipoOperadoresDefault = c13
				.filter((e) => c12.some((x: NgSelectOption<TbTipoOperador>) => x.idTipoOperador === e.tbTipoOperador?.idTipoOperador))
				.map((c) => c.tbTipoOperador)
				.filter((tbTipoOperador) => tbTipoOperador !== null)
				.map((tbTipoOperador) => tbTipoOperador as TbTipoOperador);
			this.isDataDefault.next(true);
		});
	}

	private _filtradoTipoFlete(): void {
		const viaTransporte = this.formGroup.get('tbViaTransporte')?.value as TbViaTransporte;
		const listTipoFlete = this.listTbTipoMovimientoContenedor.filter((e) => e.tbViaTransporte?.idViaTransporte == viaTransporte?.idViaTransporte);
		const convertNgSlectObject = listTipoFlete.map((e) => this.tbTipoMovimientoContenedorService.convertToNgSelect(e));
		this.tbTipoMovimientoContenedor$ = of(convertNgSlectObject);
	}

	get formGroup(): UntypedFormGroup {
		return this._formGroup;
	}

	onRedirectMaestros(key: KeyEnlacesRedireccionesMaestros): ElementsEnlace {
		return this._redirectUtilityService.onRedirectMaestros(key);
	}
}
